import  Vue  from "vue";

// 引入公共组件

// 设置对象
let  obj = {
    // key:value   注意： value 是引入的组件
}

// 循环注册
for (const key in obj) {
    Vue.component(key,obj[key])
}