// 网络请求的接口封装
import axios from "axios";

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么 ？？？   token
    // localStorage.getItem('token') && ( config.headers["X-Nideshop-Token"] = localStorage.getItem('token')  )
    config.headers["Authorization"] = 'C3B54944B9F96099CFA2AFAA52EA535533235E3D066BAD95B6EEA457826F7507'

    return config;
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么？ ？？  请求结果处理
    // response == res
    // res.data.data
    return response.data;
});


// 获取导航列表数据
export const navApi = () => {
    return axios({
        url: "/api/userPortal/navigation/getListByCon",
        method: "GET",
    })
}
// 获取index数据
export const indexApi = (params={}) => {
    return axios({
        url: "/api/userPortal/contentDistribute/getListByCon",
        method: "get",
        params
    })
}
// 获取网站配置数据
export const webApi = (params={}) => {
    return axios({
        url: "/api/userPortal/webSeting/getById",
        method: "get",
        params
    })
}