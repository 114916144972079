import Vue from 'vue'
import Vuex from 'vuex'

// 引入对应的小模块
// import  home  from  "./modules/home"

Vue.use(Vuex)

export default new Vuex.Store({
  // 全局的状态
  modules: {
    
  }
})
