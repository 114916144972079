// import { metadata } from 'core-js/fn/reflect'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index/Index.vue'

Vue.use(VueRouter)

const routes = [
  {

    path: "/index",
    component: Index,
  },
  {
    path: '/serviceAgreement',
    name: 'serviceAgreement',
    component: () => import(/* webpackChunkName: "serviceAgreement" */ '../views/details/DetailsUser'),  //用户协议
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/details/Details'),   //隐私政策
  },
  {
    path: '/',
    name: 'index',
    component: Index
  },
]

const router = new VueRouter({
  mode:'history',
  routes
})


// 处理页面跳转后不在顶部问题
// router.afterEach((to, from, next) => {
//   window.scrollTo(0, 0)
// })

export default router
