import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// // 引入重置样式
// import "./assets/css/reset.css";

// // 引入全局组件注册文件
import  "./components"

// 引入全局过滤器注册文件
import filters from "./filters"
Object.keys(filters).forEach(k=>{
  Vue.filter(k,filters[k])
})

// 引入element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// import './custom.scss'
Vue.config.productionTip = false   //是否开启生产提示
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
