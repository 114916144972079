var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index",
    on: {
      "scroll": function ($event) {
        return _vm.orderScroll($event);
      }
    }
  }, [_c('div', {
    ref: "top",
    staticClass: "top"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('el-carousel', {
    attrs: {
      "height": _vm.bannerHeight + 'px',
      "arrow": "always"
    }
  }, _vm._l(_vm.banner, function (item, index) {
    return _c('el-carousel-item', {
      key: item.id
    }, [_c('div', [_c('img', {
      ref: "bannerHeight",
      refInFor: true,
      staticClass: "carousel-image",
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "src": item.img_path.path,
        "alt": ""
      },
      on: {
        "load": _vm.imgLoad
      }
    })])]);
  }), 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showScroll,
      expression: "showScroll"
    }],
    staticClass: "scrollup"
  }, [_c('el-button', {
    staticStyle: {
      "position": "fixed",
      "top": "600px",
      "right": "30px"
    },
    attrs: {
      "circle": ""
    },
    on: {
      "click": function ($event) {
        return _vm.scrollup();
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-arrow-up"
  })])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showNav,
      expression: "showNav"
    }],
    staticClass: "navdiv"
  }, _vm._l(_vm.web, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "navcontent w"
    }, [_c('h1', [_c('img', {
      attrs: {
        "src": item.img.path,
        "alt": "",
        "height": "100%"
      },
      on: {
        "click": _vm.abouthref
      }
    })]), _c('div', {
      staticClass: "navbar",
      style: {
        'display': _vm.widthChange ? 'none' : 'block'
      }
    }, [_c('div', {
      staticClass: "navbox"
    }, _vm._l(_vm.navList, function (item, index) {
      return _c('div', {
        key: item.id,
        staticClass: "navitem",
        on: {
          "click": function ($event) {
            return _vm.routerGo(index);
          }
        }
      }, [_vm._v(_vm._s(item.name))]);
    }), 0)]), _c('div', {
      style: {
        'display': _vm.widthChange ? 'block' : 'none'
      },
      on: {
        "click": function ($event) {
          _vm.show = true;
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-menu"
    })]), _c('van-popup', {
      style: {
        height: '100%',
        width: '60%'
      },
      attrs: {
        "position": "right",
        "closeable": "",
        "close-icon-position": "top-left"
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v;
        },
        expression: "show"
      }
    }, _vm._l(_vm.navList, function (item, index) {
      return _c('div', {
        key: item.id,
        on: {
          "click": function ($event) {
            return _vm.routerGo(index);
          }
        }
      }, [_vm._v(_vm._s(item.name))]);
    }), 0)], 1);
  }), 0)]), _c('div', {
    ref: "about",
    staticClass: "about"
  }, [_c('div', {
    staticClass: "aboutcontent w"
  }, [_c('h2', [_vm._v("关于我们")]), _vm._l(_vm.company_profile, function (item, index) {
    return _c('el-row', {
      key: item.id,
      staticClass: "content"
    }, [_c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 12,
        "lg": 12,
        "xl": 12
      }
    }, [_c('div', {
      staticClass: "aboutimg"
    }, [_c('img', {
      attrs: {
        "src": item.img_path.path,
        "alt": ""
      },
      on: {
        "click": _vm.abouthref
      }
    })])]), _c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 12,
        "lg": 12,
        "xl": 12
      }
    }, [_c('div', {
      staticClass: "abouttext",
      on: {
        "click": _vm.abouthref
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })])])], 1);
  })], 2)]), _c('div', {
    ref: "travel",
    staticClass: "travel"
  }, [_c('div', {
    staticClass: "travelcontent w"
  }, [_c('h2', [_vm._v("内容展示")]), _c('div', {
    staticClass: "content"
  }, _vm._l(_vm.hot_spots, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "hotitem",
      style: {
        'width': _vm.widthChange ? _vm.width : 'calc(25% - 10px)'
      },
      on: {
        "click": function ($event) {
          return _vm.gohref(item.href);
        }
      }
    }, [_c('div', {
      staticClass: "travelcol"
    }, [_c('div', {
      staticClass: "aboutimg"
    }, [_c('img', {
      attrs: {
        "src": item.img_path.path,
        "alt": ""
      }
    })])])]);
  }), 0)])]), _vm._l(_vm.web, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "footer"
    }, [_c('div', {
      staticClass: "footer-in w"
    }, [_vm._m(0, true), _c('div', {
      staticClass: "footer-nav"
    }, [_c('dl', [_c('dt', {
      on: {
        "click": function ($event) {
          return _vm.goxieyi();
        }
      }
    }, [_vm._v("用户协议")])]), _c('dl', [_c('dt', {
      on: {
        "click": function ($event) {
          return _vm.goyinsi();
        }
      }
    }, [_vm._v("隐私条款")])]), _vm._m(1, true)])]), _c('div', {
      staticClass: "footer-copy"
    }, [_c('el-row', {
      attrs: {
        "gutter": 10
      }
    }, [_c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 12,
        "lg": 12,
        "xl": 12
      }
    }, [_c('span', [_vm._v(_vm._s(item.copyright))])]), _c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 12,
        "lg": 12,
        "xl": 12
      }
    }, [_c('div', [_vm._v(_vm._s(item.icp))])])], 1), _c('el-row', {
      attrs: {
        "gutter": 10
      }
    }, [_c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 8,
        "lg": 8,
        "xl": 8
      }
    }, [_c('div', [_vm._v(_vm._s(item.phone))])]), _c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 8,
        "lg": 8,
        "xl": 8
      }
    }, [_c('div', [_vm._v(_vm._s(item.address))])]), _c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 8,
        "lg": 8,
        "xl": 8
      }
    }, [_c('div', [_vm._v(_vm._s(item.email))])])], 1)], 1)]);
  }), _c('router-view')], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "footer-top"
  }, [_c('h2', [_vm._v("关注我们")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('dl', [_c('dt', [_vm._v("技术支持")]), _c('dd', [_vm._v("本网站由阿里云提供云计算及安全服务")]), _c('dd', [_vm._v("本网站支持IPv6")]), _c('dd', [_vm._v("Powered by CloudDream")])]);
}];
export { render, staticRenderFns };