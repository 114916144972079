import "core-js/modules/es.array.push.js";
import 'element-ui/lib/theme-chalk/display.css';
import { navApi, indexApi, webApi } from '../../request';
export default {
  name: "index",
  data() {
    return {
      showNav: true,
      showScroll: false,
      scrollTop: 0,
      slide: 0,
      sliding: null,
      screenWidth: document.body.clientWidth,
      widthChange: false,
      width: '',
      target: '',
      go: 0,
      bannerHeight: "",
      bannerwidth: "",
      navList: [],
      //导航
      banner: [],
      //banner
      company_profile: [],
      //company_profile  公司简介
      href: "",
      icon: [],
      //icon
      hot_spots: [],
      //热门景点
      strategy: [],
      //出行攻略
      news: [],
      //最新资讯
      web: [],
      show: false
    };
  },
  async created() {
    let res = await navApi();
    this.navList = res.data.data.reverse();
    // footer+logo
    let web = await webApi({
      web_seting_id: 1,
      type: 'privacy'
    });
    this.web.push(web.data);
    // banner
    let res1 = await indexApi({
      type: 'banner'
    });
    this.banner = res1.data.data;

    // 公司简介
    let res2 = await indexApi({
      type: 'company_profile'
    });
    this.company_profile = res2.data.data;
    this.href = res2.data.data[0].href;

    // 4个icon
    let res3 = await indexApi({
      type: 'icon'
    });
    this.icon = res3.data.data;

    // 热门景点
    let res4 = await indexApi({
      type: 'hot_spots'
    });
    this.hot_spots = res4.data.data;

    // 出行攻略
    let res5 = await indexApi({
      type: 'strategy'
    });
    this.strategy = res5.data.data;

    // 最新资讯
    let res6 = await indexApi({
      type: 'news'
    });
    this.news = res6.data.data;

    // window.scrollTo(0, 0)

    // 解决banner问题
    this.imgLoad();
    window.addEventListener('resize', () => {
      this.bannerHeight = this.$refs.bannerHeight[0].height;
      this.imgLoad();
    }, false);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    // 
    if (this.screenWidth <= 974 && this.screenWidth > 0) {
      this.widthChange = true;
      this.width = '100%';
      this.showScroll = false;
    } else if (this.screenWidth <= 470) {
      this.width = '100%';
      this.showScroll = false;
    } else {
      this.widthChange = false;
    }
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器

      if (this.screenWidth <= 974 && this.screenWidth > 470) {
        this.widthChange = true;
        this.width = '100%';
        this.showScroll = false;
      } else if (this.screenWidth <= 470) {
        this.width = '100%';
        this.showScroll = false;
      } else {
        this.widthChange = false;
      }
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.timer = false;
        }, 50);
      }
    }
  },
  methods: {
    showPopup() {
      this.show = true;
    },
    // banner方法
    imgLoad() {
      this.bannerHeight = this.$refs.bannerHeight[0].height;
      this.bannerwidth = document.body.clientWidth;
    },
    godownload(h) {
      window.location.href = h;
    },
    abouthref() {
      window.location.href = this.href;
    },
    gohref(hrefs) {
      window.location.href = hrefs;
    },
    goxieyi() {
      this.$router.push('/serviceAgreement');
      // document.title = "用户协议"
      window.scrollTo(0, 0);
    },
    goyinsi() {
      this.$router.push('/privacyPolicy');
      // document.title = "隐私政策"
      window.scrollTo(0, 0);
    },
    routerGo(index) {
      this.show = false;
      if (index === 0) {
        // 去首页
        this.target = this.$refs.top;
      }
      if (index === 1) {
        // 去关于我们
        this.target = this.$refs.about;
      }
      if (index === 2) {
        // 去热门景点
        this.target = this.$refs.travel;
      }
      if (index === 3) {
        // 去旅游攻略
        this.target = this.$refs.travell;
      }
      if (index === 4) {
        // 去最新资讯
        this.target = this.$refs.news;
      }
      this.go = this.target.offsetTop;
      window.scrollTo(0, this.go);
    },
    handleScroll(e) {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop > 80) {
        this.showNav = false;
        this.showScroll = true;
      } else {
        this.showNav = true;
        this.showScroll = false;
      }
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    scrollup() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth"
      });
    }
  }
};