/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./App.vue?vue&type=template&id=41240b75&"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&id=41240b75&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports