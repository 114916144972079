// import Vue  from  "vue";

// // 引入全局过滤器
// // import transPrice from  "./transPrice"
// import transLength from './transLength'

// // 配置对象
// let  obj = {
//     // key:value
//     "transLength":transLength
// }

// // 使用循环注册

// for (const key in obj) {
//     Vue.filter(key,obj[key])
// }

function transLength(value) {
    if (!value) return ''
        if (value.length > 55) {
          return value.slice(0,55) + '...'
        }
        return value
  }
  function transAboutLength(value) {
    if (!value) return ''
        if (value.length > 300) {
          return value.slice(0,300) + '...'
        }
        return value
  }
  function transDate(value) {
    if (!value) return ''
        if (value.length > 10) {
          return value.slice(0,10)
        }
        return value
  }
export default {transLength,transAboutLength,transDate}